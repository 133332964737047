/*
    Skill component shows a skill with a description and a grade (point/5 e.g. 2/5)
    The grade is shown in 5 circles, 1 filled cirle for each point, padded for the remaining points up to 5
    99 points means a header
*/
const Skill = ({ skill }) => {
    const hiddenStyle = { visibility: 'hidden'}
    if(skill.description === "empty") return <><div style={hiddenStyle}>Empty</div></>
    let grade = new Array(5)
    grade.fill(1, 0, skill.points)
    grade.fill(0, skill.points, 5)
    console.log("grade ", grade)
    let key = 0
    return (
      <>
        <span className={ skill.points === 99 ? "skillheader" : "skill" }>{skill.description.replace(/ /g, "\u00A0")}</span>
        <div className="grade">
            {               
              grade.map(point => {
                const classNames = "grade" + (point === 0 ? ` outline` : '')
                return skill.points === 99 ? "" : <svg xmlns="http://www.w3.org./2000/svg" className={classNames} key={key++}><g ><circle cx="7" cy="10" r="5" stroke="currentcolor" strokeWidth="1" fill="none"/></g></svg>
                }
              )
            }
        </div>
      </>
    )
  }
  
  export default Skill