import Skill from './Skill'
//TODO, implement sections
const SkillsMatrix = ({ skills }) => {
  let leftSkillsAmount = Math.round(skills.length / 2)
  let leftSkills = skills.filter((skill, index) => index <= leftSkillsAmount - 1)
  let rightSkills = skills.filter((skill, index) => index >= leftSkillsAmount)
  if (rightSkills.length < leftSkills.length) rightSkills.push({"description": "empty", "skill": 0})
    return (
      <>
        <div className="skillsmatrix">
          {leftSkills.map(skill =>
            <Skill key={skill.description} skill={skill} />
          )}
        </div>
        <div className="skillsmatrix">
          {rightSkills.map(skill =>
            <Skill key={skill.description} skill={skill} />
          )}
        </div>
      </>
    )
  }
  
  export default SkillsMatrix