

const Profile = ({ profile }) => { 
  console.log("--Profile.js")
  console.log("profile: " + JSON.stringify(profile))
  let i = 1;
  return (
    <>
      <h2>{profile.title}<hr/></h2>
      {
        profile.paragraphs.map(paragraph => {
          return <p key={"p" + i++}>{paragraph}</p>  
        })
      }
    </>
  )
}

export default Profile