import SkillsMatrix from './components/SkillsMatrix';
import Profile from './components/Profile';
import cv from './cv.json'
import 'material-symbols';

function App({ cvGuid }) {
    console.log("--App.js")
    console.log("cv:", cv)

    const numFoundIds = cv.ids.filter(id => id.guid === cvGuid).length
    if(numFoundIds !== 1) return (
        <div className="subwrap" id="backend-subwrap">
            <div className="backend">
                <div class="title">
                    <h1>Tim Wöstemeier</h1>
                    <div className="backend-picture"><img className="tim" src="img/timbobw_narrow.jpg" alt="Tim Wöstemeier - profiel foto" /></div>
                    <div className="content">
                        <p>Beste bezoeker,</p>
                        <p>U heeft de backend van mijn Curriculum Vitae gevonden. Voor meer informatie kunt u contact met mij opnemen via één van de onderstaande opties.</p>
                        <p>Met vriendelijke groet,<br/>
                        Tim</p>
                    </div>
                </div>
                <div className="content">
                    {/* <section className="sidebar" id="picture">
                            <img className="profile-pic" id="profile-pic-small" src="img/weide-voor-cs-bw.jpg" width="200" alt="Tim Wöstemeier - profiel foto" />
                        </section> */}
                    <section className="sidebar" id="contact">
                        <h2>Contact<hr /></h2>
                        <div className="gridlist" id="backend-gridlist">
                            <span className="material-symbols-outlined">mail</span><span className="key">Email:</span>     <span className="val"><a href="mailto:tim@timw.nl">tim@timw.nl</a></span>
                            <span className="material-symbols-outlined">call</span><span className="key">Telefoon:</span>  <span className="val"><a href="tel:+31638229417">06-38229417</a></span>
                            {/* <span className="material-symbols-outlined">cake</span><span className="key">Geboren:</span>   <span className="val">24 Juni 1980</span> */}
                            <span className="icon" id="linkedin"><a href="https://www.linkedin.com/in/timwostemeier/">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" data-supported-dps="34x34" width="34" height="34" focusable="false">
                                    <g transform="scale(.35)" fill="none" fillRule="evenodd">
                                        <rect className="bug-text-color" fill="#264d73" x="1" y="1" width="46" height="46" rx="4"></rect>
                                        <path d="M0 4.01A4.01 4.01 0 014.01 0h39.98A4.01 4.01 0 0148 4.01v39.98A4.01 4.01 0 0143.99 48H4.01A4.01 4.01 0 010 43.99V4.01zM19 18.3h6.5v3.266C26.437 19.688 28.838 18 32.445 18 39.359 18 41 21.738 41 28.597V41.3h-7V30.159c0-3.906-.937-6.109-3.32-6.109-3.305 0-4.68 2.375-4.68 6.109V41.3h-7v-23zM7 41h7V18H7v23zm8-30.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z" className="background" fill="#FFF"></path>
                                    </g>
                                </svg></a></span><span className="key" id="linkedin"><a href="https://www.linkedin.com/in/timwostemeier/">LinkedIn</a></span><span className="val" id="linkedin">https://www.linkedin.com/in/timwostemeier/</span>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        
    )

    const { guid, naturalId } = cv.ids.find(id => id.guid === cvGuid)

    console.log("cvGuid: " + cvGuid)
    console.log("guid: " + guid)
    console.log("active id: " + naturalId)

    const activeProfile = cv.profiles.find(profile => profile.naturalId === naturalId)
    console.log('activeProfile:', activeProfile)
    const activeSkills = cv.skillMatrices.find(matrix => matrix.naturalId === naturalId).skills
    console.log('activeSkills:', activeSkills)

    if(numFoundIds === 1) {
        return (
            <div className="subwrap">
                <div className="profile">
                    <div><span id="print-notice">Dit is een printout. Voor het origineel ga naar: https://timwostemeier.nl/?cv={ cvGuid }</span></div>
                    <h1>Tim Wöstemeier<span id="titlecv"> - Curriculum Vitae</span></h1>
                    <div className="header-image"><img className="profile-pic" src="img/weide-voor-cs-br.jpg" alt="Tim Wöstemeier - profiel foto"/></div>
                    <div className="content">
                        <section className="sidebar" id="picture">
                            <img className="profile-pic" id="profile-pic-small" src="img/weide-voor-cs-bw.jpg" width="200" alt="Tim Wöstemeier - profiel foto" />
                        </section>
                        <section className="sidebar" id="contact">
                            <h2>Contact<hr /></h2>
                            <div className="gridlist">
                                <span className="material-symbols-outlined">cake</span><span className="key">Geboren:</span>   <span className="val">24 Juni 1980</span>
                                <span className="material-symbols-outlined">home</span><span className="key">Woonplaats:</span><span className="val">Amersfoort</span>
                                <span className="material-symbols-outlined">mail</span><span className="key">Email:</span>     <span className="val"><a href="mailto:tim@timw.nl">tim@timw.nl</a></span>
                                <span className="material-symbols-outlined">call</span><span className="key">Telefoon:</span>  <span className="val"><a href="tel:+31638229417">06-38229417</a></span>
                                <span className="icon" id="linkedin"><a href="https://www.linkedin.com/in/timwostemeier/">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" data-supported-dps="34x34" width="34" height="34" focusable="false">
                                        <g transform="scale(.35)" fill="none" fillRule="evenodd">
                                            <rect className="bug-text-color" fill="#264d73" x="1" y="1" width="46" height="46" rx="4"></rect>
                                            <path d="M0 4.01A4.01 4.01 0 014.01 0h39.98A4.01 4.01 0 0148 4.01v39.98A4.01 4.01 0 0143.99 48H4.01A4.01 4.01 0 010 43.99V4.01zM19 18.3h6.5v3.266C26.437 19.688 28.838 18 32.445 18 39.359 18 41 21.738 41 28.597V41.3h-7V30.159c0-3.906-.937-6.109-3.32-6.109-3.305 0-4.68 2.375-4.68 6.109V41.3h-7v-23zM7 41h7V18H7v23zm8-30.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z" className="background" fill="#FFF"></path>
                                        </g>
                                    </svg></a></span><span className="key" id="linkedin"><a href="https://www.linkedin.com/in/timwostemeier/">LinkedIn</a></span><span className="val" id="linkedin">https://www.linkedin.com/in/timwostemeier/</span>
                            </div>
                        </section>
                        <section className="sidebar printvert" id="profile">
                            <Profile profile={activeProfile} />
                        </section>
                        <section className="sidebar printvert" id="traits">
                            <h2>Eigenschappen<hr /></h2>
                            <ul>
                                <li>Empathisch</li>
                                <li>Nauwkeurig</li>
                                <li>Creatief</li>
                                <li>Communicatief</li>
                            </ul>
                        </section>
                        <section className="sidebar printvert" id="languages">
                            <h2>Talen<hr /></h2>
                            Nederlands, Engels, Duits
                        </section>
                    </div>
                </div>
                <div className="experience">
                    <h1>Tim Wöstemeier</h1>
                    <div className="content">
                        <section id="skills">
                            <h2>Vaardigheden<hr /></h2>
                            <div className="flexwrap">
                                <SkillsMatrix skills={activeSkills} />
                            </div>
                        </section>
                        <section id="work-experience">
                            <h2>Werkervaring<hr /></h2>
                            <section id="graafmachine-machinist">
                                <h3>2023 - 2024  - Smink infra, graafmachine machinist</h3>
                                <p>Als graafmachine machinist in opleiding ben ik ingezet op verschillende soorten projecten. Waar nodig voerde ik ook grondwerk uit.<br/>
                                    Mijn taken bestonden onder andere uit ontgraven, aanvullen, afwerken, hijsen en aansluiten, met aandacht voor de veiligheid en de omgeving.<br/>
                                {/* <span className="emphasize">Toegepaste technieken en vaardigheden o.a.: </span> bediening van zwaar materieel, meetgereedschap, handgereedschap en gemotoriseerd gereedschap; inmeten, bewerken en aansluiten van afvoer -en drainage systemen. */}</p>
                            </section>
                            <section id="software-engineer">
                                <h3>2013 - 2022  - ATS Applied Tech Systems, software engineer</h3>
                                <p>Als software engineer was ik verantwoordelijk voor het adviseren op functioneel en technisch vlak, het ontwerpen, ontwikkelen en ondersteunen van maatwerk software oplossingen binnen verschillende technische kaders en begeleiden van collega’s op het gebied van softwareontwikkeling.</p>
                                <h4>2014 - 2022</h4>
                                <p>Voor meerdere klanten in onder andere de voedingsindustrie in teamverband maatwerkaanpassingen en -integraties ontworpen, ontwikkeld en uitgerold voor toegepaste bestaande softwarepakketten t.b.v. specificatiemanagement, procesbeheersing en kwaliteitscontrole.<br/>
                                <span className="emphasize">Toegepaste technieken en vaardigheden o.a.: </span> SQL, T-SQL, PL/SQL, VB .NET, C# .NET, DevOps, Git, XML/XSD/XSLT, requirements vaststellen, documenteren, diagrammen, functioneel testen, coachen</p>
                                <h4>2013 - 2019</h4>
                                <p>Voor een Duits bedrijf gespecialiseerd in kathodische bescherming van gebouwen heb ik in direct contact met de klant een bestaande maatwerk software oplossing doorontwikkeld. Deze software oplossing bestond uit een embedded linux pc met meet- en regelsoftware voor het aansturen van specialistische hardware en een windows applicatie voor het configureren en uitlezen van deze embedded pc’s. 
                                    Later heb ik in teamverband een webportal ter vervanging van de windows applicatie ontworpen en ontwikkeld.<br/>
                                <span className="emphasize">Toegepaste technieken en vaardigheden o.a.: </span> C/C++, Qt, HTML, CSS, Javascript, AngularJS, TotalJS, PostgreSQL, json, XML, Modbus, SQLite, Git, requirements vaststellen, documenteren, diagrammen, Linux, functioneel testen, prototypen, wireframen, UX.</p>
                            </section>
                            <section id="divers">
                                <h3>2001 - 2011 - Divers o.a.</h3>
                                <p>Winkelmedewerker, beveiliger op Schiphol, zeilmaker, metaalwerker</p>
                            </section>
                        </section>
                        <section id="references">
                            <h2>Referenties<hr /></h2>
                            <p>Referenties op aanvraag</p>
                        </section>
                        <section id="courses">
                            <h2>Cursussen<hr /></h2>
                            <ul>
                                <li>2014 - 2019 - Verschillende Simatic IT Applicaties</li>
                                <li>2017 - Certified Profinet Engineer</li>
                                <li>2014 - GAMP</li>
                                <li>2014 - C / C++</li>
                                <li>2014 - Database ontwerp en beheer</li>
                                <li>2001 - Rijbewijs B (+ T)</li>
                            </ul>
                        </section>
                        <section id="education">
                            <h2>Opleidingen<hr /></h2>
                            <section id="ti">
                                <h3>2011 - 2019 - Technische Informatica, Hogeschool van Arnhem en Nijmegen</h3>
                                <section className="experience">
                                    <h4>2019 - Afstudeerproject</h4>
                                    <p>Tijdens mijn afstudeerproject heb ik in opdracht van mijn werkgever ATS zelfstandig aan een proof of concept voor gerobotiseerde 3D-scanoplossing ontwikkeld die zelfstandig objecten kan scannen ter kwaliteitscontrole.
                                        Ik heb tijdens het project ook een stagair begeleid in het ontwerpen en prototypen van een met de robot geïntegreerd gereedschap t.b.v. het proof of concept.<br/>
                                    <span className="emphasize">Toegepaste technieken en vaardigheden o.a.: </span> C# .NET, XAML, IronPython, Git, Universal Robot, unit testen, onderzoek, documenteren, diagrammen, agile, coachen.</p>
                                    <h4>2013 - Minor Robotica</h4>
                                    <p>Tijdens mijn minor heb ik in een multidisciplinair team een gerobotiseerd bordspel 'Mens erger je niet' ontworpen en ontwikkeld. Ik was hierin verantwoordelijk voor de aansturing van de pionnen middels een zelf ontworpen electromagnetische aandrijving.<br/>
                                    <span className="emphasize">Toegepaste technieken en vaardigheden o.a.: </span> Electronisch ontwerp, printplaat ontwikkelen, Arduino, C++, ontwerpen, prototypen, onderzoek, documenteren, diagrammen.</p>
                                </section>
                            </section>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
    
}

// ,
//         {
//           "description": "Persoonlijke vaardigheden/kwaliteiten",
//           "points": 99
//         },
//         {


export default App;
